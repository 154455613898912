import styled from 'styled-components';

const CustomForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 4;
  padding-top: 54px;

  @media screen and (min-width: 768px) {
    padding-top: 68px;
  }
`;

export default CustomForm;
import styled from 'styled-components';

const AskAgain = styled.button`
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-size: 28px;
  font-size: 1.75rem;
  letter-spacing: 0.06em;
  color: white;
  border-radius: 0;
  background: none;
  border: 0;
  border-bottom: 3px solid white;
  padding: 0 0 2px;
  cursor: pointer;
  transition: opacity 0.21s ease-out;

  &:hover,
  &:focus {
    opacity: 0.75;
    outline: none;
  }
`;

export default AskAgain;
import styled, { css } from 'styled-components';
import enterArrow from '../images/arrow_enter.svg';

const Instructions = styled.h2`
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-size: 28px;
  font-size: 1.75rem;
  letter-spacing: 0.06em;
  color: white;
  margin: 0;
  opacity: 0;

  ${props =>
    props.init &&
    css`
      transition: opacity 0.6s 0.6s ease-out;
      opacity: 1;
    `};

  & button {
    display: inline-block;
    padding: 0 0 5px;
    position: relative;
    font-family: acumin-pro-extra-condensed, sans-serif;
    font-size: 28px;
    font-size: 1.75rem;
    letter-spacing: 0.06em;
    background: none;
    border-radius: 0;
    border: 0;
    color: white;
    cursor: pointer;
    transition: opacity 0.21s ease-out;

    &:hover,
    &:focus {
      opacity: 0.75;
    }

    &::before {
      content: url(${enterArrow});
      width: 64px;
      position: absolute;
      left: -9px;
      bottom: -12px;
    }
  }
`;

export default Instructions;
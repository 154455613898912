import React from 'react';
import styled, { keyframes } from 'styled-components';
import { darkBlue, pink } from '../colors';
import Eye from '../images/venn_eye.inline.svg';

const gradientFade = keyframes`
  0% { background-position: 10% 0% }
  50% { background-position: 50% 0% }
  100% { background-position: 90% 0% }
`;

const AboutWrapper = styled.section`
  height: auto;
  min-height: 100vh;
  background-color: ${darkBlue};
  display: block;
  padding: 12vh 12vw 12vh 9vw;

  @media screen and (min-width: 820px) {
    padding: 12vh 12vw 12vh 9vw;
  }

  & svg {
    width: 31.8vw;
    position: absolute;
    right: 5.6vw;
    bottom: -22px;

    @media screen and (min-width: 500px) {
      bottom: -60px;
    }
  }
`;

const AboutText = styled.p`
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 1;
  letter-spacing: 0.02em;
  margin: 0 0 48px;
  padding-bottom: 20px;
  background: linear-gradient(90deg, rgba(233,171,194,1) 0%, rgba(187,141,186,1) 15%, rgba(119,97,174,1) 30%, rgba(37,43,159,1) 50%, rgba(119,97,174,1) 70%, rgba(187,141,186,1) 85%, rgba(233,171,194,1) 100%);
  background-size: 300% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  position: relative;
  z-index: 1;
  animation: ${gradientFade} 18s infinite alternate linear;

  @media screen and (min-width: 500px) {
    font-size: 46px;
    font-size: 2.875rem;
  }

  @media screen and (min-width: 820px) {
    font-size: 62px;
    font-size: 3.875rem;
  }
`;

const Credit = styled.p`
  font-size: 18px;
  font-size: 1.125rem;
  color: ${pink};
  text-transform: uppercase;
  letter-spacing: 0.15em;
  position: relative;
  z-index: 1;

  @media screen and (min-width: 500px) {
    font-size: 22px;
    font-size: 1.375rem;
  }

  & span {
    display: block;

    @media screen and (min-width: 500px) {
      display: inline-block;
    }
  }

  & a {
    color: white;
    text-decoration: none;
    border-bottom: 2px solid white;
    padding-bottom: 3px;
  }
`;

const About = () => {
  return (
    <AboutWrapper>
      <Eye/>

      <AboutText>
        Like the crystal ball, tarot cards or the Ouija board, the AI Ball possess deep mystical powers. The AI Ball knows all and can provide believers with a 20/20 view of things yet to come. So query the AI Ball for answers to your deepest questions, but beware while the AI Ball is all knowing it is not great at keeping secrets.
      </AboutText>

      <Credit>
        <span>Powered by</span> <a href="https://www.walltowall.com">Wall-to-Wall Studios</a>
      </Credit>
    </AboutWrapper>
  );
};

export default About;
import React, { useEffect, useState } from 'react';
import SecretsSpinner from '../images/secrets_spinner.inline.svg';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinnerMask = styled.div`
  width: 0vw;
  height: 0vw;
  border-radius: 50%;
  overflow: hidden;
  clip-path: circle(50%);
  position: fixed;
  left: 50%;
  z-index: 6;
  transform: translate(-50%, -50%);
  transition: width 1s ease-out,
    height 1s ease-out;
  pointer-events: none;

  & svg {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    animation: ${spin} 60s infinite linear;
  }
`;

const Spinner = props => {
  const { grow, top } = props;
  const [portrait, setPortrait] = useState(false);

  useEffect(() => {
    getWindowSize();
    window.addEventListener('resize', getWindowSize);

    return () => {
      window.removeEventListener('resize', getWindowSize);
    };
  }, []);


  const getWindowSize = () => {
    if (window.innerWidth < window.innerHeight) {
      setPortrait(true);
    } else {
      setPortrait(false);
    }
  }

  return (
    <SpinnerMask
      grow={grow}
      style={{
        top,
        width: grow && portrait
          ? '100vh'
          : grow
          ? '100vw'
          : '0vw',
        height: grow && portrait
          ? '100vh'
          : grow
          ? '100vw'
          : '0vw',
      }}
    >
      <SecretsSpinner
        style={{
          width: portrait
            ? '100vh'
            : '100vw',
          marginLeft: portrait
            ? '-50vh'
            : '-50vw',
          marginTop: portrait
            ? '-50vh'
            : '-50vw'
        }}
      />
    </SpinnerMask>
  );
};

export default Spinner;
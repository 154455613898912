import styled, { css } from 'styled-components';
import { blue, pink } from '../colors';

const Corner = styled.div`
  width: 42%;
  background-color: ${blue};
  border: 1.25vw solid ${pink};
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  opacity: 0;

  @media screen and (min-width: 960px) {
    width: 21.7%;
    border-width: 0.6vw;
  }

  ${props =>
    props.init &&
    css`
      opacity: 1;
      transition: opacity 0.6s 0.6s ease-out;
    `};

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;
  }

  & img {
    width: 12vw;
    position: absolute;

    @media screen and (min-width: 960px) {
      width: 6vw;
    }
  }

  &:nth-child(3) {
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);

    & img {
      bottom: 12.4%;
      right: 19%
    }
  }

  &:nth-child(4) {
    top: 0;
    right: 0;
    transform: translate(50%, -50%);

    & img {
      bottom: 12.4%;
      left: 19%
    }
  }

  &:nth-child(5) {
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);

    & img {
      top: 12.4%;
      left: 19%
    }
  }

  &:nth-child(6) {
    bottom: 0;
    left: 0;
    transform: translate(-50%, 50%);

    & img {
      top: 12.4%;
      right: 19%
    }
  }

  ${props =>
    props.pink &&
    css`
      background-color: ${pink};
      border-color: ${blue};
  `};
`;

export default Corner;
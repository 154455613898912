import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Stars from '../components/Stars';
import Frame from '../components/Frame';
import Corner from '../components/Corner';
import hourglassPink from '../images/Pink_v1.gif';
import hourglassBlue from '../images/Blue_v1.gif';
import LargeCircle from '../components/LargeCircle';
import InnerRing from '../components/InnerRing';
import CustomForm from '../components/CustomForm';
import FieldWrapper from '../components/FieldWrapper';
import Question from '../components/Question';
import Ball from '../components/Ball';
import Instructions from '../components/Instructions';
import AskAgain from '../components/AskAgain';
import About from '../components/About';
import RevealButton from '../components/RevealButton';
import '../styles/index.css';
import { DateTime } from 'luxon';
import filter from 'leo-profanity';
import socialCard from '../images/social_card.jpg';

import firebase from 'firebase/app';
import 'firebase/firestore';

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyBO7tDI-xToGi2kEwC9aGthEOwlFU1c348",
    authDomain: "ai-ball-ad20f.firebaseapp.com",
    projectId: "ai-ball-ad20f"
  });
}

filter.add([
  'fucked',
  'fucking'
]);

filter.remove([
  'butt',
  'cornhole',
  'shitblimp',
  'suck',
  'sucks',
  'xxx',
  'xx',
  'sexy'
]);

const db = firebase.firestore();

function Index() {
  const [init, setInit] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [query, setQuery] = useState('');
  const [answer, setAnswer] = useState({
    title: '',
    description: '',
    image: ''
  });
  const [shakeBall, setShakeBall] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const inputField = useRef(null);

  const defaultAnswers = [
    {
      answer: '¯\\_(ツ)_/¯',
      description: 'Shrug'
    },
    {
      answer: 'My artificial gut is telling me yes',
      description: 'My artificial gut is telling me yes'
    },
    {
      answer: 'Nope',
      description: 'Nope'
    },
    {
      answer: '🤙',
      description: 'Shaka'
    }
  ];

  useEffect(() => {
    fetch('/api/session')
      .then(response => response.json())
      .then(data => {
        setSessionId(data.result.session_id);
      })
      .catch(error => {
        console.error(error);
      });

    const timer = setTimeout(() => {
      setInit(true);
    }, 2000);

    return () => {
      setInit(false);
      clearTimeout(timer);
    }
  }, []);

  const getAnswer = event => {
    event.preventDefault();

    if (query) {
      setShakeBall(true);
      inputField.current.blur();

      fetch('/api/message', {
        method: 'post',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          session_id: sessionId,
          input: {
            message_type: 'text',
            text: query
          }
        })
      }).then(response => response.json())
        .then(data => {
          const generic = data.result.output.generic;

          if (generic.length) {
            const randomAnswer = generic[Math.floor(Math.random() * generic.length)];

            if ((randomAnswer.title || '') && !randomAnswer.title.toLowerCase().match(/talk to someone/)) {
              db.collection("secrets").add({
                query: filter.clean(query),
                answer: randomAnswer.title,
                description: randomAnswer.description,
                timestamp: DateTime.local().toString()
              });

              updateCounter();
            }

            setAnswer({
              title: randomAnswer.title,
              description: randomAnswer.description,
              image: randomAnswer.source
            });

            setTimeout(() => {
              setShakeBall(false);
            }, 250);
          } else {
            const randomAnswer = defaultAnswers[Math.floor(Math.random() * defaultAnswers.length)];

            db.collection("secrets").add({
              query: filter.clean(query),
              answer: randomAnswer.answer,
              description: randomAnswer.description,
              timestamp: DateTime.local().toString()
            });

            updateCounter();

            setAnswer({
              ...answer,
              title: randomAnswer.answer,
              description: randomAnswer.description,
            });

            setTimeout(() => {
              setShakeBall(false);
            }, 250);
          }
        })
        .catch(error => {
          setAnswer({
            ...answer,
            title: 'Something Went Wrong',
            description: 'Something Went Wrong',
          });

          setTimeout(() => {
            setShakeBall(false);
          }, 250);
        });
    }
  }

  const updateCounter = () => {
    const counterRef = db.collection("counters").doc("answers");

    counterRef.get().then(doc => {
      if (doc.exists) {
        counterRef.update({
          count: firebase.firestore.FieldValue.increment(1)
        });
      } else {
        counterRef.set({
          count: 1
        });
      }
    })
  }

  const resetForm = () => {
    setQuery('');
    setShakeBall(true);

    setTimeout(() => {
      setAnswer({
        title: '',
        description: '',
        image: ''
      });
      setShakeBall(false);
    }, 250);
  }

  const showSpinner = () => {
    setSpinner(true);
  }

  return (
    <>
      <main>
        <Helmet>
          <link rel="stylesheet" href="https://use.typekit.net/bsy8vcq.css"/>
          <title>AI Ball</title>

          <meta property="og:title" content="Ask the AI Ball" />
          <meta property="og:url" content="https://asktheaiball.com" />
          <meta property="og:image" content={socialCard} />

          {/* Google Tag Manager */}
          <script>
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-5949DKN');
            `}
          </script>
          {/* End Google Tag Manager */}
        </Helmet>

        <section>
          <Stars/>
          <Frame init={init}/>

          <Corner pink init={init}>
            <img src={hourglassBlue} alt="" style={{
              display: init ? 'block' : 'none'
            }}/>
          </Corner>

          <Corner init={init}>
            <img src={hourglassPink} alt="" style={{
              display: init ? 'block' : 'none'
            }}/>
          </Corner>

          <Corner pink init={init}>
            <img src={hourglassBlue} alt="" style={{
              display: init ? 'block' : 'none'
            }}/>
          </Corner>

          <Corner init={init}>
            <img src={hourglassPink} alt="" style={{
              display: init ? 'block' : 'none'
            }}/>
          </Corner>

          <LargeCircle/>
          <LargeCircle/>

          <CustomForm onSubmit={getAnswer}>
            <Question
              show={answer.image || answer.title}
            >
              {query}
            </Question>

            <FieldWrapper
              init={init}
              hide={answer.image || answer.title}
            >
              <input
                type="text"
                name="query"
                value={query}
                onChange={event => setQuery(event.target.value) }
                ref={inputField}
                placeholder="Ask the AI Ball..."
                autoComplete="off"
              />
            </FieldWrapper>

            <Ball
              answer={answer}
              show={(answer.image || answer.title) && !shakeBall}
              shake={shakeBall}
              init={init}
              spinner={spinner}
            />

            {answer.image || answer.title ?
              <AskAgain
                onClick={resetForm}
                type="button"
              >
                Ask Again
              </AskAgain>
            :
              <Instructions
                init={init}
              >
                <button type="submit">
                  Enter
                </button>
              </Instructions>
            }
          </CustomForm>

          <InnerRing/>
          <RevealButton
            init={init}
            spinner={showSpinner}
          />
        </section>

        <About/>
      </main>

      {/* Google Tag Manager (noscript) */}
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-5949DKN"
            title="GTM"
            height="0"
            width="0"
            style={{
              display: 'none',
              visibility: 'hidden'
            }}
          />
        </noscript>
      {/* End Google Tag Manager (noscript) */}
    </>
  );
}

export default Index;

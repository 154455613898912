import styled from 'styled-components';
import { blue } from '../colors';

const LargeCircle = styled.div`
  width: 120%;
  border: 0.2vw solid ${blue};
  border-radius: 50%;
  position: absolute;
  left: 50%;
  display: none;

  @media screen and (min-width: 1160px) {
    display: block;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;
  }

  &:nth-child(7) {
    top: 66px;
    transform: translateX(-50%);
  }

  &:nth-child(8) {
    bottom: 66px;
    transform: translateX(-50%);
  }
`;

export default LargeCircle;
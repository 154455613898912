import styled, { css } from 'styled-components';
import { purple } from '../colors';

const FieldWrapper = styled.div`
  background: linear-gradient(90deg, rgba(233,171,194,1) 0%, rgba(187,141,186,1) 30%, rgba(119,97,174,1) 50%, rgba(4,22,153,1) 100%);
  width: 82vw;
  flex: 1 1 auto;
  max-width: 500px;
  height: 54px;
  border-radius: 34px;
  padding: 5px;
  opacity: 0;
  position: absolute;
  top: 0;

  @media screen and (min-width: 768px) {
    max-width: 644px;
    padding: 8px;
    height: 68px;
  }

  ${props =>
    props.init &&
    css`
      transition: opacity 0.6s 0.6s ease-out;
      opacity: 1;
    `};

  ${props =>
    props.hide &&
    css`
      transition: transform 0.3s ease-out, opacity 0.3s ease-out;
      transform: scale(0.9);
      opacity: 0;
      pointer-events: none;
    `};

  & input {
    width: 100%;
    height: 100%;
    padding: 0 18px 2px;
    border: 0;
    border-radius: 26px;
    background-color: ${purple};
    font-family: acumin-pro-extra-condensed, sans-serif;
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 700;
    color: white;
    text-align: center;

    @media screen and (min-width: 768px) {
      font-size: 28px;
      font-size: 1.75rem;
      padding: 0 22px 4px;
    }

    &::placeholder {
      opacity: 1;
      color: white;
      transition: opacity 0.21s ease-out;
    }

    &:focus {
      outline: none;

      &::placeholder {
        opacity: 0;
      }
    }
  }
`;

export default FieldWrapper;
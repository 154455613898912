import React, { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { darkGrey } from '../colors';
import Back from '../images/back.inline.svg';
import ballBackground from '../images/ball.svg';
import ballRing from '../images/ball_ring.svg';
import message from '../images/message.png';
import Spinner from './Spinner';

const shake = keyframes`
  0% {
    left: 0;
  }
  25% {
    left: 20px;
  }
  75% {
    left: -20px;
  }
  100% {
    left: 0;
  }
`;

const float = keyframes`
  0% {
    top: -10px;
  }
  50% {
    top: 10px;
  }
  100% {
    top: -10px;
  }
`

const BallBackground = styled.div`
  width: 360px;
  height: 362px;
  border-radius: 50%;
  overflow: hidden;
  padding-top: 68px;
  background: url(${ballBackground}) center no-repeat;
  background-size: 100% auto;
  margin-top: -9px;
  position: relative;
  z-index: 4;
  animation: ${float} 2.5s infinite alternate ease-in-out;

  @media screen and (min-width: 500px) {
    width: 484px;
    height: 486px;
    padding-top: 92px;
  }

  ${props =>
    props.shake &&
    css`
      animation: 0.25s ${shake} ease-out;
    `};

  & > svg {
    width: 155px;
    height: 155px;
    position: absolute;
    left: 102px;
    transition: left 0.6s ease-in-out;

    @media screen and (min-width: 500px) {
      width: 209px;
      height: 209px;
      left: 138px;
    }
  }

  & div:first-of-type {
    width: 155px;
    height: 159px;
    margin: 0px auto 0;
    padding: 16px;
    overflow: hidden;
    position: relative;
    left: 348px;
    transition: left 0.6s 0.6s ease-in-out;

    @media screen and (min-width: 500px) {
      width: 209px;
      height: 214px;
      padding: 22px;
    }

    &::before {
      content: '';
      width: 125px;
      height: 125px;
      position: absolute;
      top: 16px;
      left: 16px;
      z-index: 1;
      background-color: ${darkGrey};
      border-radius: 50%;

      @media screen and (min-width: 500px) {
        width: 168px;
        height: 168px;
        top: 22px;
        left: 22px;
      }
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      background: url(${ballRing}) center no-repeat;
    }
  }

  & span {
    background-color: ${darkGrey};
    display: block;
    width: 125px;
    height: 125px;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    transition: transform 0.4s ease-out,
      opacity 0.4s ease-out;
    transform: scale(0.75) skew(-4deg, 6deg);
    opacity: 0;

    @media screen and (min-width: 500px) {
      width: 168px;
      height: 168px;
    }

    ${props =>
      props.show &&
      css`
        transform: scale(1) skew(0, 0);
        opacity: 1;
        transition: transform 0.4s 0.15s ease-out,
          opacity 0.4s 0.15s ease-out;
      `};

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      margin-top: 15px;
    }

    & h1 {
      background: url(${message}) top 2% center;
      background-size: auto 122%;
      width: 125px;
      height: 125px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0;
      padding: 32px 40px 40px;
      font-family: trade-gothic-next, sans-serif;
      font-weight: 700;
      font-size: 10px;
      font-size: 0.625rem;
      text-transform: uppercase;

      @media screen and (min-width: 500px) {
        font-size: 12px;
        font-size: 0.75rem;
        width: 168px;
        height: 168px;
        padding: 32px 40px 40px;
      }
    }
  }

  ${props =>
    props.init &&
    css`
      & > svg {
        left: -210px;
      }

      & > div:first-of-type {
        left: 0;
      }
    `};
`;

const Ball = props => {
  const { answer, init, shake, show, spinner } = props;
  const [topPosition, setTopPosition] = useState(null);
  const ballRef = useRef(null);

  useEffect(() => {
    if (ballRef.current && spinner) {
      const offset = window.innerWidth >= 500 ? 200 : 150;
      const topPosition = ballRef.current.getBoundingClientRect().top + offset;

      setTopPosition(`${topPosition}px`);
    }
  }, [ballRef, spinner]);

  return (
    <>
      <BallBackground
        show={show}
        shake={shake}
        init={init}
        ref={ballRef}
      >
        <Back/>

        <div>
          <span>
            {answer.image ?
              <img src={answer.image} alt={answer.description}/>
            : answer.title ?
              <h1>{answer.title}</h1>
            : null}
          </span>
        </div>
      </BallBackground>

      <Spinner
        ball={ballRef}
        grow={spinner}
        top={topPosition}
      />
    </>
  );
}

export default Ball;
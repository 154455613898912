import styled, { css } from 'styled-components';

const Frame = styled.div`
  width: calc(100% - 16vw);
  height: calc(100% - 16vw);
  position: absolute;
  top: 8vw;
  left: 8vw;
  z-index: 2;
  border: 1.25vw solid;
  border-image-source: linear-gradient(40deg, rgba(233,171,194,1) 0%, rgba(4,22,153,1) 40%, rgba(4,22,153,1) 60%, rgba(233,171,194,1) 100%);
  border-image-slice: 1;
  opacity: 0;

  @media screen and (min-width: 740px) {
    width: calc(100% - 9.4vw);
    height: calc(100% - 9.4vw);
    top: 4.7vw;
    left: 4.7vw;
  }

  @media screen and (min-width: 960px) {
    border-width: 0.6vw;
  }

  ${props =>
    props.init &&
    css`
      transition: opacity 0.6s 0.6s ease-out;
      opacity: 1;
    `};
`;

export default Frame;
import React from 'react';
import styled, { keyframes } from 'styled-components';

import Ring from '../images/inner_ring.inline.svg';
import Leaf from '../images/icon_leaf.inline.svg';
import Flower from '../images/icon_flower.inline.svg';
import Snowflake from '../images/icon_snowflake.inline.svg';
import Diamond from '../images/icon_diamond.inline.svg';
import Rose from '../images/icon_rose.inline.svg';
import Hand from '../images/icon_hand.inline.svg';
import Sun from '../images/icon_sun.inline.svg';
import Tears from '../images/icon_tears.inline.svg';

const outerRotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const innerRotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`;

const Outer = styled.div`
  display: none;
  width: 92vh;
  height: 92vh;
  position: absolute;
  top: 4vh;
  left: 50%;
  margin-left: -46vh;
  padding: 1.9vw;
  animation: 60s ${outerRotate} infinite linear;
  will-change: transform;
  z-index: 3;

  @media screen and (min-width: 666px) {
    display: block;
  }

  @media screen and (min-width: 960px) {
    width: 92vw;
    height: 92vw;
    top: 50%;
    left: 4vw;
    transform: translateY(-50%);
    margin-top: -46vw;
    margin-left: 0;
  }

  @media screen and (min-width: 1600px) {
    width: 79.3vw;
    height: 79.3vw;
    left: 10.35vw;
    margin-top: -39.65vw;
  }
`;

const Icon = styled.div`
  width: 6vw;
  height: 6vw;
  position: absolute;
  animation: 60s ${innerRotate} infinite linear;
  will-change: transform;

  @media screen and (min-width: 960px) {
    width: 4.6vw;
    height: 4.6vw;
  }

  &:nth-child(2) {
    left: 0;
    top: 50%;
    margin-top: -3vw;

    @media screen and (min-width: 960px) {
      margin-top: -2.3vw;
    }
  }

  &:nth-child(3) {
    left: 12.6vh;
    top: 12.6vh;

    @media screen and (min-width: 960px) {
      left: 12.8vw;
      top: 12.8vw;
    }

    @media screen and (min-width: 1600px) {
      left: 10.9vw;
      top: 10.9vw;
    }
  }

  &:nth-child(4) {
    left: 50%;
    top: 0;
    margin-left: -3vw;

    @media screen and (min-width: 960px) {
      margin-left: -2.3vw;
    }
  }

  &:nth-child(5) {
    right: 12.6vh;
    top: 12.6vh;

    @media screen and (min-width: 960px) {
      right: 12.8vw;
      top: 12.8vw;
    }

    @media screen and (min-width: 1600px) {
      right: 10.9vw;
      top: 10.9vw;
    }
  }

  &:nth-child(6) {
    right: 0;
    top: 50%;
    margin-top: -3vw;

    @media screen and (min-width: 960px) {
      margin-top: -2.3vw;
    }
  }

  &:nth-child(7) {
    right: 12.6vh;
    bottom: 12.6vh;

    @media screen and (min-width: 960px) {
      right: 12.8vw;
      bottom: 12.8vw;
    }

    @media screen and (min-width: 1600px) {
      right: 10.9vw;
      bottom: 10.9vw;
    }
  }

  &:nth-child(8) {
    left: 50%;
    bottom: 0;
    margin-left: -3vw;

    @media screen and (min-width: 960px) {
      margin-left: -2.3vw;
    }
  }

  &:nth-child(9) {
    left: 12.6vh;
    bottom: 12.6vh;

    @media screen and (min-width: 960px) {
      left: 12.8vw;
      bottom: 12.8vw;
    }

    @media screen and (min-width: 1600px) {
      left: 10.9vw;
      bottom: 10.9vw;
    }
  }
`;

const InnerRing = () => {
  return (
    <Outer>
      <Ring/>

      <Icon>
        <Leaf/>
      </Icon>

      <Icon>
        <Flower/>
      </Icon>

      <Icon>
        <Snowflake/>
      </Icon>

      <Icon>
        <Diamond/>
      </Icon>

      <Icon>
        <Rose/>
      </Icon>

      <Icon>
        <Hand/>
      </Icon>

      <Icon>
        <Sun/>
      </Icon>

      <Icon>
        <Tears/>
      </Icon>
    </Outer>
  );
};

export default InnerRing;
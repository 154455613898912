import styled, { css } from 'styled-components';
import { pink } from '../colors';

const Question = styled.h1`
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-size: 32px;
  font-size: 2rem;
  color: ${pink};
  line-height: 1.1;
  margin: 0;
  text-align: center;
  position: absolute;
  bottom: calc(100% - 40px);
  width: 700px;
  max-width: 100%;
  opacity: 0;
  transform: scale(0.9);
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;

  @media screen and (min-width: 600px) {
    font-size: 48px;
    font-size: 3rem;
  }

  @media screen and (min-width: 768px) {
    bottom: calc(100% - 52px);
  }

  ${props =>
    props.show &&
    css`
      transition: transform 0.3s 0.3s ease-out, opacity 0.3s 0.3s ease-out;
      transform: scale(1);
      opacity: 1;
    `};
`;

export default Question;
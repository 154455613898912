import React from 'react';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import { purple } from '../colors';

const RevealBackground = styled.button`
  background: linear-gradient(90deg, rgba(233,171,194,1) 0%, rgba(187,141,186,1) 30%, rgba(119,97,174,1) 50%, rgba(4,22,153,1) 100%);
  border: 0;
  border-radius: 0;
  width: 178px;
  height: 56px;
  padding: 1.25vw;
  line-height: 1;
  position: absolute;
  bottom: 12px;
  z-index: 3;
  cursor: pointer;
  opacity: 0;

  @media screen and (min-width: 460px) {
    bottom: 8.75vw;
    transform: translateY(50%);
  }

  @media screen and (min-width: 610px) {
    width: 240px;
    height: 72px;
  }

  @media screen and (min-width: 740px) {
    width: 348px;
    height: 64px;
    bottom: 5.25vw;
  }

  @media screen and (min-width: 960px) {
    padding: 0.6vw;
    bottom: 5vw;
  }

  & span {
    padding: 0 8px;

    @media screen and (min-width: 610px) {
      padding: 0 32px;
    }

    @media screen and (min-width: 740px) {
      padding: 0;
    }
  }

  &:hover,
  &:focus {
    & span {
      color: rgba(255, 255, 255, 0.75);
    }
  }

  ${props =>
    props.init &&
    css`
      transition: opacity 0.6s 0.6s ease-out;
      opacity: 1;
    `};
`;

const RevealInner = styled.span`
  display: block;
  height: 100%;
  background-color: ${purple};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 0.18em;
  color: white;
  text-transform: uppercase;
  font-family: acumin-pro-extra-condensed, sans-serif;
  transition: color 0.3s ease-out;
`;

const RevealButton = props => {
  const openSecrets = () => {
    props.spinner();

    setTimeout(() => {
      navigate('/secrets');
    }, 2000);
  }

  return (
    <>
      <RevealBackground
        init={props.init}
        onClick={openSecrets}
        type="button"
      >
        <RevealInner>
          Reveal the secrets of the universe
        </RevealInner>
      </RevealBackground>
    </>
  );
};

export default RevealButton;
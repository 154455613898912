import React from 'react';
import styled from 'styled-components';
import Star from '../images/star.png';
import Particles from 'react-particles-js'

const StarWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.25;

  & > div {
    width: 100%;
    height: 100%;
  }
`;

const Stars = () => {
  return (
    <StarWrapper>
      <Particles
        params={{
          particles: {
            number: {
              value: 300,
              density: {
                  enable: true,
                  value_area: 800
              }
            },
            line_linked: {
              enable: false
            },
            move: {
              speed: 0.25,
              out_mode: "out"
            },
            shape: {
              type: 'image',
              image: { src: Star, height: 16, width: 16 },
            },
            color: {
              value: "#e1e888"
            },
            size: {
              value: 16,
              random: true,
              anim: {
                enable: true,
                speed: 4,
                size_min: 1,
                sync: false
              }
            }
          }
        }}
      />
    </StarWrapper>
  );
};

export default Stars;